import { QueryClient } from '@tanstack/react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnWindowFocus: true,
      // refetchOnMount: false,
      // refetchIntervalInBackground: true,
      // refetchInterval: 180000,
      // retry: true,
    },
  },
})
